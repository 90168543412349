import React, { useState, useEffect } from "react";
import { ListGroup, Button, Spinner, Form } from 'react-bootstrap';
import { getAuth } from "firebase/auth";

function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);



  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true); // Start loading
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken();
            const response = await fetch(`https://us-central1-sepa-firehouse.cloudfunctions.net/fetchUsers?agencyAccessCode=${agencyAccessCode}`, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
          });
          const data = await response.json();
          // Filter out users with accountDenied set to true
          const filteredUsers = data.filter(user => !user.accountDenied);
          setUsers(filteredUsers);
          setFilteredUsers(filteredUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
          
        } finally {
          setIsLoading(false); // End loading
        }
      } else {
        setIsLoading(false); // End loading if no user
      }
    };

    fetchUsers();
  }, [agencyAccessCode]);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const searchedUsers = users.filter(user => 
      user.name.toLowerCase().includes(lowercasedSearchTerm) ||
      user.email.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredUsers(searchedUsers);
  }, [searchTerm, users]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const manageUserAccount = async (userId, action) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      fetch('https://us-central1-sepa-firehouse.cloudfunctions.net/manageUserAccount', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId, action })
      })
      .then(response => {
        if (response.ok) {
          // Update the users state based on the action
          if (action === 'authorize' || action === 'disable') {
            setUsers(users.map(u => u.id === userId ? { ...u, accountAuthorized: action === 'authorize' } : u));
          } else if (action === 'deny') {
            setUsers(users.filter(u => u.id !== userId));
          }
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .catch(error => {
        console.error("Error managing user account:", error);
      });
    }
  };

  const authorizeUser = (id) => manageUserAccount(id, 'authorize');
  const denyUser = (id) => manageUserAccount(id, 'deny');
  const disableAccount = (id) => manageUserAccount(id, 'disable');

  return (
    <>
     {isLoading ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
         </Spinner>
       </div>
      ) : (
      <>
        <Form.Control
          type="search"
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px' }}
        />
    <ListGroup>
      {filteredUsers.map((user) => (
        <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center">
          <div>{user.name} - {user.email}</div>
          <div>
            {user.accountAuthorized ? (
              <Button variant="danger" onClick={() => disableAccount(user.id)}>Disable Account</Button>
            ) : (
              <>
                <Button variant="success" className="me-2" onClick={() => authorizeUser(user.id)}>Authorize</Button>
                <Button variant="danger" onClick={() => denyUser(user.id)}>Deny</Button>
              </>
            )}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
    </>
    )}
    </>
  );
}

export default UserList;
